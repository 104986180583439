<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- Striped Rows -->
    <div class="col-xxl-9">
      <div class="card mt-xxl-n5">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>

              <th scope="col">Code Filière</th>

              <th scope="col">Filière</th>

              <th scope="col">cycle</th>

              <th scope="col">Année</th>

              <th scope="col">Titulaire</th>

              <th scope="col">Action</th>

            </tr>

          </thead>

          <tbody>
            <tr v-for="filiere in fileres" :key="filiere.id">
              <th scope="row">{{ filiere.id }}</th>

              <td> {{ filiere.code_filiere }} </td>

              <td> {{ filiere.nom_filiere }} </td>

              <td> {{ filiere.niveau_formation }} </td>

              <td> {{ filiere.annee_formation }} </td>

              <td v-if="filiere.department_professors_history[0]?.professeur">
                {{ filiere.department_professors_history[0]?.professeur?.nom }} {{
                  filiere.department_professors_history[0]?.professeur?.prenom }}
              </td>

              <td v-else class="text-danger">
                <b>Aucun titulaire</b>
              </td>

              <td>
                <div class="hstack gap-3 flex-wrap">
                  <a href="javascript:void(0);" @click="navEncryptFiliere(filiere)" class="link-success fs-15"><i
                      class="ri-pencil-line"></i></a>
                  <a href="javascript:void(0);" @click="deleteFaculty(filiere.id)" class="link-danger fs-15"><i
                      class="ri-delete-bin-line"></i></a>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Layout>
</template>
  
<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import  CryptoJS  from 'crypto-js';
import { SHA256 } from 'crypto-js';
// import axios from 'axios';
import Swal from "sweetalert2";

export default {
  setup() { },

  data() {
    return {
      data: {},
      fileres: {},
      title: "Liste",
      items: [
        {
          text: "Filere",
          href: "/",
        },
        {
          text: "Tout",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.getAllFileres();
  },

  methods: {
    getAllFileres() {
      const self = this;
      this.$store.dispatch('getRequest', { route: 'api/departments', data: this.data })
        .then(
          function (response) {
            self.fileres = response.data.filieres;
          },
          function (error) {
            console.log(error);
          }
        )
    },
    navEncryptFiliere(filiere) {
      const filiereString1 = JSON.stringify(filiere);
      const encryptionKey1 = 'Slim#9065';
      const encrypted1 = CryptoJS.AES.encrypt(filiereString1, encryptionKey1);
      const encryptedMessage1 = encrypted1.toString();
      localStorage.setItem('dataF', encryptedMessage1);

      const filiereString = JSON.stringify(filiere.id);
      const hash = SHA256(filiereString).toString();
      this.$router.push({ path: `/filiere/${hash}` })
    },
    async deleteFaculty(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette alerte? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          this.$store.dispatch('postRequest', { route: 'api/departments ', data: {idenfiant: id} })
            .then(
              function (response) {
                self.fileres = response.data.filieres;
                Swal.fire({
                  title: "Supprimer",
                  text: 'Faculté supprimée avec succès!',
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            )
        }
      })


    }
  },
};
</script>
  
  